<template>
  <v-btn
    v-if="rows.length > 1"
    id="change"
    :elevation="0"
    color="gray"
    small
    style="border-radius: 0 0 0 .3em; background: rgba(255,215,215,0.7)"
    tile
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    @click.prevent="$emit('update:delete', [rows, index])"
  >
    <p :style="isHover ? 'width: 60px' : ''" class="ma-0 pa-0 red--text">{{ $t('general.buttons.delete') }}</p>
    <v-icon color="red" style='font-size: 22px'>mdi-delete-outline</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'DeleteGroupButtonComponent',

    props: {
      index: {
        type: Number,
        required: true
      },
      rows: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        isHover: false
      }
    }
  }
</script>

<style scoped>
  #change p {
    width: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
  }

  #change:hover p {
    width: 130px;
    max-width: 100%;
    overflow-x: hidden;
  }
</style>